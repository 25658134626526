<template>
  <div class="bg-decoration flex min-h-screen w-full items-center justify-center relative flex-col overflow-auto">
    <NuxtLink :to="ROUTES.home">
      <BaseImage
        height="2rem"
        width="144px"
        src="/hirable-logo.png"
        :alt="$config.public.appName"
        class="mb-3 sm:absolute sm:top-8 left-8 sm:mb-0"
      />
    </NuxtLink>
    <div class="flex w-full justify-center items-center px-2 sm:max-w-4xl">
      <slot />
    </div>
  </div>
</template>
